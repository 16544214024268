import { IS_SSR } from "@uplift-ltd/constants";
import {
  useAssertUserContext as useAssertUserContextNexus,
  UserContextProvider as NexusUserContextProvider,
  useUserContext as useUserContextNexus,
} from "@uplift-ltd/use-user-context";
import gql from "graphql-tag";
import React from "react";
import {
  UserContextCurrentUser as CurrentUser,
  UserContextQuery,
} from "./__generated__/useUserContext";

interface CurrentUserWithNonNullEmail extends CurrentUser {
  email: string;
}

interface UserContextWithNonNullEmail extends UserContextQuery {
  currentUser: CurrentUserWithNonNullEmail | null;
}

export type {
  UserContextWithNonNullEmail as UserContext,
  CurrentUserWithNonNullEmail as CurrentUser,
};

export const CURRENT_USER_QUERY = gql`
  query UserContext {
    isAuthenticated
    currentUser {
      id
      databaseId
      name
      firstName
      lastName
      email
      credits
      avatar {
        url
      }
      userFields {
        licenseData {
          type
          licenseNumber
          state
        }
        city
        state
        zip
      }
      roles {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export const useUserContext = () => useUserContextNexus<CurrentUserWithNonNullEmail>();

export const useTypedAssertUserContext = () =>
  useAssertUserContextNexus<CurrentUserWithNonNullEmail>();

export const UserContextProvider: React.FC<{ skip: boolean }> = ({ children, skip = IS_SSR }) => (
  <NexusUserContextProvider<UserContextWithNonNullEmail>
    currentUserQuery={CURRENT_USER_QUERY}
    skip={skip}
  >
    {children}
  </NexusUserContextProvider>
);
