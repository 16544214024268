import { ApolloError, useEnhancedQuery } from "@uplift-ltd/apollo";
import gql from "graphql-tag";
import React, { createContext, useContext, useMemo } from "react";
import { MAIN_MENU_ID, SECONDARY_MENU_ID } from "constants/site";
import { mapNodes } from "helpers/mapNodes";
import {
  MenuItemsQueryMenu as Menu,
  MenuItemsQueryNode as MenuItem,
  MenuItemsQueryQuery as MenuItemsQuery,
  MenuItemsQueryVariables,
} from "./__generated__/useMenuItemsContext";

export type { MenuItemsQuery, MenuItem, MenuItemsQueryVariables };

export const MENU_ITEMS_QUERY = gql`
  query MenuItemsQuery($menuId: ID!) {
    menu(id: $menuId) {
      id
      menuItems(first: 100) {
        edges {
          node {
            id
            label
            path
            parentId
            childItems {
              edges {
                node {
                  id
                  label
                  path
                }
              }
            }
          }
        }
      }
    }
  }
`;

interface MenuQueryShape {
  menu: Menu | null;
}

export type MenuShape = MenuQueryShape & {
  loading: boolean;
  error?: ApolloError;
};

interface MenuContextProviderProps {
  children: React.ReactNode;
  skip?: boolean;
}

interface MenuContextShape {
  menus: Record<string, MenuShape>;
}

export const MenuContext = createContext<MenuContextShape>({
  menus: {
    [MAIN_MENU_ID]: { menu: null, loading: true },
    [SECONDARY_MENU_ID]: { menu: null, loading: true },
  },
});

export function useMenuItemsContext() {
  return useContext<MenuContextShape>(MenuContext);
}

// Predicate for top-level menu items (is not a child)
const isTopLevelMenuItem = (menuItem: MenuItem) => !menuItem.parentId;

export function useAssertMenuItemsContext(menuId: string) {
  const { menus } = useMenuItemsContext();

  const { loading, menu } = menus[menuId];

  if (loading) {
    return [];
  }

  if (!menu) {
    throw new Error(`Menu not found, using ID: ${menuId}.`);
  }

  if (!menu?.menuItems) {
    throw new Error(`Menu items not found, using ID: ${menuId}.`);
  }

  return mapNodes(menu?.menuItems).filter(isTopLevelMenuItem);
}

export function useAssertMainMenuItemsContext() {
  return useAssertMenuItemsContext(MAIN_MENU_ID);
}

export function useAssertSecondaryMenuItemsContext() {
  return useAssertMenuItemsContext(SECONDARY_MENU_ID);
}

function useMenuItems(menuId: string, skip = false) {
  return useEnhancedQuery<MenuItemsQuery, MenuItemsQueryVariables>(
    MENU_ITEMS_QUERY,
    {
      variables: { menuId },
      skip,
    },
    {
      auth: false,
    }
  );
}

export function MenuContextProvider({ children, skip = false }: MenuContextProviderProps) {
  const { data: mainMenuData, loading: mainMenuLoading } = useMenuItems(MAIN_MENU_ID, skip);

  const { data: secondaryMenuData, loading: secondaryMenuLoading } = useMenuItems(
    SECONDARY_MENU_ID,
    skip
  );

  const menus = useMemo(() => {
    const { menu: mainMenu } = mainMenuData || { menu: null };
    const { menu: secondaryMenu } = secondaryMenuData || { menu: null };

    return {
      menus: {
        [MAIN_MENU_ID]: { menu: mainMenu, loading: mainMenuLoading },
        [SECONDARY_MENU_ID]: { menu: secondaryMenu, loading: secondaryMenuLoading },
      },
    };
  }, [mainMenuData, mainMenuLoading, secondaryMenuData, secondaryMenuLoading]);

  return <MenuContext.Provider value={menus}>{children}</MenuContext.Provider>;
}
