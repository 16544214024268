import { gql, useEnhancedQuery } from "@uplift-ltd/apollo";
import { IS_SSR } from "@uplift-ltd/constants";
import { useCallback, useState } from "react";
import { removeTrailingSlash } from "helpers/strings";
import useRouterNavigation from "hooks/useRouterNavigation";
import { RedirectionRulesQueryQuery as RedirectionRulesQuery } from "./__generated__/useServerSideRedirects";

export const REDIRECTION_RULES_QUERY = gql`
  query RedirectionRulesQuery {
    redirection {
      redirects {
        origin
        target
        type
        code
        groupId
        groupName
        matchType
      }
    }
  }
`;

const useServerSideRedirects = () => {
  // this keeps the slug stable while the redirect happens from 404
  const [slug] = useState(() => {
    if (IS_SSR) return "";

    return removeTrailingSlash(window.location.pathname);
  });
  const routerNav = useRouterNavigation();
  const { data, loading } = useEnhancedQuery<RedirectionRulesQuery>(REDIRECTION_RULES_QUERY);

  const redirection =
    data?.redirection?.redirects?.find(
      item => item?.origin === slug && item.groupName?.toLowerCase() === "next.js"
    ) || undefined;

  const redirect = useCallback(() => {
    if (redirection?.target) {
      routerNav.push(redirection.target);
    }
  }, [redirection?.target, routerNav]);

  return {
    loading,
    hasRedirect: !!redirection,
    redirect,
  };
};

export default useServerSideRedirects;
