import { gql, useEnhancedQuery } from "@uplift-ltd/apollo";
import {
  UserFieldsOptionsQuery,
  UserFieldsOptionsUserFieldsOptions as UserFieldsOptions,
} from "./__generated__/useUserFieldsOptions";

export type { UserFieldsOptions };

export const USER_FIELDS_OPTIONS_QUERY = gql`
  query UserFieldsOptions {
    userFieldsOptions {
      country {
        label
        value
      }
      degree {
        label
        value
      }
      license {
        licenseType {
          label
          value
        }
      }
      namePrefix {
        label
        value
      }
      state {
        label
        value
      }
      profession {
        label
        value
      }
      professionalDesignation {
        label
        value
      }
    }
  }
`;

const DEFAULT_COUNTRIES: UserFieldsOptions["country"] = [];
const DEFAULT_DEGREES: UserFieldsOptions["degree"] = [];
const DEFAULT_LICENSES: UserFieldsOptions["license"] = {
  __typename: "LicenseOptions",
  licenseType: [],
};
const DEFAULT_NAME_PREFIXES: UserFieldsOptions["namePrefix"] = [];
const DEFAULT_STATES: UserFieldsOptions["state"] = [];
const DEFAULT_PROFESSIONS: UserFieldsOptions["profession"] = [];

export function useUserFieldsOptions() {
  const { data, loading } = useEnhancedQuery<UserFieldsOptionsQuery>(USER_FIELDS_OPTIONS_QUERY);

  const country = data?.userFieldsOptions.country || DEFAULT_COUNTRIES;
  const degree = data?.userFieldsOptions.degree || DEFAULT_DEGREES;
  const license = data?.userFieldsOptions.license || DEFAULT_LICENSES;
  const namePrefix = data?.userFieldsOptions.namePrefix || DEFAULT_NAME_PREFIXES;
  const state = data?.userFieldsOptions.state || DEFAULT_STATES;
  const profession = data?.userFieldsOptions.profession || DEFAULT_PROFESSIONS;

  return {
    countryOptions: country,
    degreeOptions: degree,
    professionOptions: profession,
    licenseStateOptions: state,
    licenseTypeOptions: license.licenseType,
    namePrefixOptions: namePrefix,
    stateOptions: state,
    loading,
  };
}
