import { useEffect, useRef } from "react";

export const useAnchorRef = <T extends HTMLElement>(id: string | null) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    if (window.location.hash.replace(/[^a-zA-Z ]/g, "") === id) {
      ref.current?.scrollIntoView();
    }
  }, [id]);

  return ref;
};
