import {
  ApolloLink,
  ConfigureClientOptions,
  GRAPHQL_TOKEN,
  initClient,
  InMemoryCache,
} from "@uplift-ltd/apollo";
import { SentryLink } from "apollo-link-sentry";
import { useMemo } from "react";
import { CourseOrCourseGroup } from "__generated__/globalTypes";
import { APOLLO_STATE_PROP_NAME } from "constants/apollo";
import { HOME_URL, LOGOUT_URL } from "constants/urls";

const sentryMiddleware = new SentryLink();

const woocommerceSessionMiddleware = new ApolloLink((operation, forward) => {
  /**
   * If session data exist in local storage, set value as session header.
   */
  if (typeof localStorage !== "undefined") {
    const session = localStorage.getItem("woo-session");
    if (session) {
      operation.setContext(() => ({
        headers: {
          "woocommerce-session": `Session ${session}`,
        },
      }));
    }
  }

  return forward(operation);
});

const woocommerceSessionAfterware = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    /**
     * Check for session header and update session in local storage accordingly.
     */
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;
    const session = headers.get("woocommerce-session");
    if (session) {
      if (typeof localStorage !== "undefined") {
        if (localStorage.getItem("woo-session") !== session) {
          localStorage.setItem("woo-session", headers.get("woocommerce-session"));
        }
      }
    }

    return response;
  });
});

export const configureClient = ({
  initialState,
  cookie,
}: Pick<ConfigureClientOptions, "initialState" | "cookie"> = {}) => {
  const cache = new InMemoryCache({
    typePolicies: {
      User: {
        fields: {
          userFields: {
            merge: true,
          },
        },
      },
      MyCoursesProgressGroup: {
        fields: {
          courses: {
            merge(existing = [], incoming: CourseOrCourseGroup[]) {
              return incoming.length ? incoming : existing;
            },
          },
        },
      },
      Query: {
        fields: {
          userFieldsOptions: {
            merge: true,
          },
          myCourses: {
            keyArgs: false,
          },
        },
      },
    },
  });

  return initClient({
    cache,
    initialState,
    cookie,
    extraLinks: [
      sentryMiddleware.concat(woocommerceSessionMiddleware.concat(woocommerceSessionAfterware)),
    ],
    getToken() {
      if (typeof localStorage === "undefined") {
        return null;
      }
      return localStorage.getItem(GRAPHQL_TOKEN);
    },
    removeToken() {
      if (typeof localStorage === "undefined") {
        return;
      }
      localStorage.removeItem(GRAPHQL_TOKEN);
    },
    onNotAuthorized() {
      if (typeof window === "undefined") {
        return;
      }
      window.location.assign(LOGOUT_URL);
    },
    onForbidden() {
      if (typeof window === "undefined") {
        return;
      }
      window.location.assign(HOME_URL);
    },
    batch: true,
  });
};

export type InitialStateShape = ConfigureClientOptions["initialState"];

interface UseApolloPageProps<CacheShape> {
  [APOLLO_STATE_PROP_NAME]?: CacheShape;
}

export type ApolloPageProps = UseApolloPageProps<InitialStateShape>;

export function useApollo<PageProps extends UseApolloPageProps<unknown>>(pageProps: PageProps) {
  const initialState = pageProps[APOLLO_STATE_PROP_NAME];
  return useMemo(
    () => configureClient({ initialState: initialState as ConfigureClientOptions["initialState"] }),
    [initialState]
  );
}
