import { ParsedUrlQuery, stringify } from "querystring";
import identity from "lodash/identity";
import pickBy from "lodash/pickBy";
import { useRouter } from "next/router";

export type ParsedRouterQueryStrings = Record<string, string | undefined>;

export function useRouterQuery<QueryShape extends ParsedUrlQuery = ParsedRouterQueryStrings>() {
  const router = useRouter();

  const updateRouterQuery = (newQuery: ParsedUrlQuery) => {
    const query = pickBy({ ...router.query, ...newQuery }, identity);
    const q = stringify(query);
    if (q) {
      router.replace(`${router.pathname}?${q}`);
    } else {
      router.replace(router.pathname);
    }
  };

  return {
    routerQuery: router.query as QueryShape,
    updateRouterQuery,
  };
}
