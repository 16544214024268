import {
  BrowserClient,
  defaultIntegrations,
  defaultStackParser,
  makeFetchTransport,
} from "@sentry/browser";
import { SENTRY_DSN } from "@uplift-ltd/sentry";
import React, { createContext, useContext } from "react";

interface SentryBrowserContextShape {
  sentryBrowserClient: BrowserClient | null;
}

const SentryBrowserContext = createContext<SentryBrowserContextShape>({
  sentryBrowserClient: null,
});

export function SentryBrowserProvider({ children }: { children: React.ReactNode }) {
  const sentryBrowserClient = new BrowserClient({
    dsn: SENTRY_DSN,
    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    integrations: defaultIntegrations,
  });

  return (
    <SentryBrowserContext.Provider value={{ sentryBrowserClient }}>
      {children}
    </SentryBrowserContext.Provider>
  );
}

export const useSentryBrowserContext = () => {
  return useContext(SentryBrowserContext);
};
