import "styles/index.css";
import { ApolloProvider } from "@uplift-ltd/apollo";
import { IS_SSR } from "@uplift-ltd/constants";
import type { AppProps } from "next/app";
import { ReCaptchaProvider } from "next-recaptcha-v3";
import React from "react";
import { UIDReset } from "react-uid";
import { ApolloPageProps, useApollo } from "helpers/apollo";
import { MenuContextProvider } from "hooks/useMenuItemsContext";
import { PageMetaProvider } from "hooks/usePageMeta";
import { SentryBrowserProvider } from "hooks/useSentryBrowserContext";
import { UserContextProvider } from "hooks/useUserContext";
import "../../sentry.client.config";
import Scripts from "pages/scripts";

interface PageProps extends ApolloPageProps {
  isAuthenticated: boolean;
}

function AppInner({ Component, pageProps }: AppProps<PageProps>) {
  const client = useApollo(pageProps);

  return (
    <ApolloProvider client={client}>
      <SentryBrowserProvider>
        <UserContextProvider skip={IS_SSR && !pageProps.isAuthenticated}>
          <ReCaptchaProvider>
            <PageMetaProvider>
              <MenuContextProvider>
                <Scripts />
                <Component {...pageProps} />
              </MenuContextProvider>
            </PageMetaProvider>
          </ReCaptchaProvider>
        </UserContextProvider>
      </SentryBrowserProvider>
    </ApolloProvider>
  );
}

function App(props: AppProps<PageProps>) {
  return (
    <UIDReset>
      <AppInner {...props} />
    </UIDReset>
  );
}

export default App;
