export const E164_REGEX = /^\+[1-9]\d{10,14}/;

export function getUsernameFromEmail(email: string) {
  return email.replace(/\+/g, "-");
}

export function telToE164(tel: string) {
  let result = tel.replace(/[^+|\d]/g, "");
  // if it doesn't start with + it's not in E164
  if (result.length > 0 && !result.startsWith("+")) {
    // if it's 10 digits it's likely a US phone number
    if (result.length === 10) {
      result = `+1${result}`;
    } else {
      result = `+${result}`;
    }
  }
  return result;
}

export const formatTokens = (numTokens: number | string): string => {
  const tokens = typeof numTokens === "string" ? parseInt(numTokens, 10) : numTokens;
  return tokens === 1 ? "1 token" : `${tokens} tokens`;
};

export function formatUSD(amount: number, keepZeroes = false) {
  const formattedAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount || 0);

  return keepZeroes ? formattedAmount : formattedAmount.replace(/\.00$/, "");
}

export const parseFloatFromCurrencyString = (value: string | undefined | null): number => {
  if (!value) return 0;
  return parseFloat(value.split("$")[1] || "0");
};

export const formatUSDFromCurrencyString = (
  value: string | undefined | null,
  keepZeroes = false
): string => {
  return formatUSD(parseFloatFromCurrencyString(value), keepZeroes);
};

const TRAILING_SLASH_REGEX = /\/$/;

export const removeTrailingSlash = (path: string) => path.replace(TRAILING_SLASH_REGEX, "");

const removeDivAndParagraphTags = (content: string) => {
  return content.replace(/<\/?(div|p)[^><]*>/g, "");
};

export const removeHTMLTags = (content: string) => {
  return content.replace(/(<([^>]+)>)/gi, "");
};

export const replaceNonBreakingSpaces = (content: string) => {
  return content.replace(/\u00a0/g, " ").replace(/&nbsp;/g, " ");
};

// Strip div/p tags which cannot be children of p tags
// (causes Warning: Prop `dangerouslySetInnerHTML` did not match)
// Strip &nbsp;
// (causes layout issues)
// https://zur-institute.atlassian.net/jira/software/c/projects/ZUR/boards/2?modal=detail&selectedIssue=ZUR-271&search=271
export const sanitizeWordpressContent = (content: string): string => {
  return replaceNonBreakingSpaces(removeDivAndParagraphTags(content));
};
