import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { SITE_NAME } from "constants/site";

type BasePageMeta = {
  title?: string | null;
  canonical?: string | null;
  metaDesc?: string | null;
  metaKeywords?: string | null;
};

export type PageMeta = BasePageMeta & {
  opengraphImage?: {
    id: string;
    mediaItemUrl: string | null;
  } | null;
};

export type SimplifiedPageMeta = BasePageMeta & {
  metaImage?: string | null;
};

export const pageMetaToSimplifiedPageMeta = (
  providedMeta?: PageMeta | null
): SimplifiedPageMeta => {
  if (providedMeta) {
    const { opengraphImage, ...meta } = providedMeta;

    return {
      ...meta,
      metaImage: opengraphImage?.mediaItemUrl || "",
    };
  }

  return {};
};

type PageMetaContextShape = {
  pageMeta: SimplifiedPageMeta;
  pageTitle: string;
  resetPageMeta: () => void;
  resetPageTitle: () => void;
  setPageMeta: React.Dispatch<React.SetStateAction<SimplifiedPageMeta>>;
  setPageTitle: React.Dispatch<React.SetStateAction<string | null | undefined>>;
};

const PageMetaContext = React.createContext<PageMetaContextShape>({
  setPageMeta: () => {
    /* noop */
  },
  resetPageMeta: () => {
    /* noop */
  },
  setPageTitle: () => {
    /* noop */
  },
  resetPageTitle: () => {
    /* noop */
  },
  pageTitle: "",
  pageMeta: {
    title: "",
    canonical: "",
    metaDesc: "",
    metaKeywords: "",
    metaImage: "",
  },
});

export const usePageMetaContext = () => useContext(PageMetaContext);

const getInitialPageMeta = (): SimplifiedPageMeta => ({
  title: "",
  canonical: "",
  metaDesc: "",
  metaKeywords: "",
  metaImage: "",
});

type PageMetaProviderProps = {
  defaultPageTitle?: string;
  defaultSiteName?: string;
};

export const PageMetaProvider: React.FC<PageMetaProviderProps> = ({
  children,
  defaultPageTitle,
  defaultSiteName = SITE_NAME,
}) => {
  const [pageTitle, setPageTitle] = useState<string | null | undefined>(defaultPageTitle);
  const [pageMeta, setPageMeta] = useState(getInitialPageMeta);

  const resetPageTitle = useCallback(() => setPageTitle(defaultPageTitle), [defaultPageTitle]);

  const contextValue = useMemo(() => {
    const title = pageTitle ? `${pageTitle} – ${defaultSiteName}` : defaultSiteName;

    return {
      pageTitle: title,
      setPageTitle,
      resetPageTitle,
      pageMeta: {
        title: pageMeta.title || title,
        metaDesc: pageMeta.metaDesc || "",
        metaKeywords: pageMeta.metaKeywords || "",
        canonical: pageMeta.canonical || "",
        metaImage: pageMeta.metaImage || "",
      },
      setPageMeta,
      resetPageMeta: getInitialPageMeta,
    };
  }, [defaultSiteName, pageTitle, pageMeta, resetPageTitle]);

  return <PageMetaContext.Provider value={contextValue}>{children}</PageMetaContext.Provider>;
};

export const usePageMeta = (newPageTitle: string | null | undefined, seo?: PageMeta | null) => {
  const { setPageMeta, resetPageMeta, setPageTitle, resetPageTitle } = usePageMetaContext();

  const seoProvided = !!seo;
  const {
    title = "",
    canonical = "",
    metaDesc = "",
    metaKeywords = "",
    opengraphImage,
  } = seo || {};

  useEffect(() => {
    setPageTitle(newPageTitle);

    if (seoProvided) {
      const metaImage = opengraphImage?.mediaItemUrl || "";

      setPageMeta(prevMeta => ({
        ...prevMeta,
        title,
        canonical,
        metaDesc,
        metaKeywords,
        metaImage,
      }));
    }

    return () => {
      resetPageMeta();
      resetPageTitle();
    };
  }, [
    canonical,
    metaDesc,
    metaKeywords,
    newPageTitle,
    opengraphImage,
    resetPageMeta,
    resetPageTitle,
    seoProvided,
    setPageMeta,
    setPageTitle,
    title,
  ]);
};
