"use client";

import Script from "next/script";
import React from "react";
import {
  GTM_CONTAINER_ID,
  GTM_ENGINE_IS_RED_ID,
  KLAVIYO_PUBLIC_API_KEY,
  META_ENGINE_IS_RED_PIXEL_ID,
} from "constants/site";
import { useUserContext } from "hooks";

const Scripts = () => {
  const { currentUser } = useUserContext();

  return (
    <>
      {GTM_CONTAINER_ID && GTM_ENGINE_IS_RED_ID && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${GTM_CONTAINER_ID}`}
            strategy="afterInteractive"
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());
    
              gtag('config', '${GTM_CONTAINER_ID}');
              gtag('config', '${GTM_ENGINE_IS_RED_ID}');
            `}
          </Script>
        </>
      )}
      {META_ENGINE_IS_RED_PIXEL_ID && (
        <Script id="meta-analytics" strategy="afterInteractive">
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${META_ENGINE_IS_RED_PIXEL_ID}'); 
            fbq('track', 'PageView');
          `}
        </Script>
      )}
      {KLAVIYO_PUBLIC_API_KEY && (
        <Script
          src={`//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${KLAVIYO_PUBLIC_API_KEY}`}
          strategy="afterInteractive"
          onLoad={() => {
            if (currentUser) {
              // The Klaviyo object is not available immediately after script load, so wait for it
              const id = setInterval(() => {
                if (window.klaviyo) {
                  window.klaviyo.push([
                    "identify",
                    {
                      $email: currentUser.email,
                      $first_name: currentUser.firstName,
                      $last_name: currentUser.lastName,
                      LicenceTypes: currentUser.userFields?.licenseData?.map(
                        licenseData => licenseData?.type
                      ),
                      City: currentUser.userFields?.city,
                      State: currentUser.userFields?.state,
                      ZIP: currentUser.userFields?.zip,
                    },
                  ]);

                  clearInterval(id);
                }
              }, 200);
            }
          }}
        />
      )}
    </>
  );
};

export default Scripts;
