export const HOME_URL = "/";
export const REGISTER_URL = "/register";

export const LOGIN_URL = "/login";
export const LOGOUT_URL = "/logout";

export const PRIVACY_URL = "/privacy-disclaimer-terms-of-use-dmca/#privacy";
export const TERMS_URL = "/privacy-disclaimer-terms-of-use-dmca/#terms";
export const DMCA_URL = "/privacy-disclaimer-terms-of-use-dmca/#dmca";

export const PROFILE_URL = "/profile";

export const CONTACT_URL = "/contact";
export const HELP_DESK_PORTAL_URL = "https://support.zurinstitute.com/portal/en/home";

export const CE_REQUIREMENTS_SLUG = "ce_requirements";
export const CE_REQUIREMENTS_URL = `/${CE_REQUIREMENTS_SLUG}` as const;
export const CE_REQUIREMENTS_DETAIL_URL = `/${CE_REQUIREMENTS_SLUG}/:state/:profession` as const;

export const COURSES_URL = "/courses";
export const COURSE_URL_BASE = `${COURSES_URL}/:slug` as const;
export const COURSE_URL = `${COURSE_URL_BASE}/:page` as const;

const COURSE_CATALOG_ROOT_URL = "/course-catalog";
export const COURSE_CATALOG_BY_SUBJECT = `${COURSE_CATALOG_ROOT_URL}/subjects` as const;

export const COURSE_CATALOG_BY_TITLE = `${COURSE_CATALOG_ROOT_URL}/title` as const;
export const COURSE_CATALOG_BY_AUTHOR = `${COURSE_CATALOG_ROOT_URL}/authors` as const;
export const COURSE_CATALOG_BY_STATE = `${COURSE_CATALOG_ROOT_URL}/states` as const;
export const COURSE_CATALOG_BY_NUMBER_OF_CE = `${COURSE_CATALOG_ROOT_URL}/ce-units` as const;

export const BOARD_APPROVAL_URL = "/licensing-board-approvals";
export const BUNDLE_URL = `/packages/:slug`;

export const EVENTS_URL = "/webinars";
export const EVENT_URL = `${EVENTS_URL}/:slug` as const;

export const RESET_PASSWORD_URL = "/reset-password";

export const COURSE_AUTHOR_URL = "/course-author/:slug";
export const ORDER_HISTORY_URL = "/order-history";
export const BUY_TOKENS_URL = "/buy-tokens";

export const CART_URL = "/cart";
export const SEARCH_PAGE_URL = "/search";

export const PRIVATE_PRACTICE_TOOLS_ROOT_URL = "/private-practice-tools";
export const PRIVATE_PRACTICE_TOOLS_URL = `${PRIVATE_PRACTICE_TOOLS_ROOT_URL}/:slug` as const;
export const ESSENTIAL_CLINICAL_FORMS_URL =
  `${PRIVATE_PRACTICE_TOOLS_ROOT_URL}/essential-clinical-forms` as const;
export const HIPAA_COMPLIANCE_KIT =
  `${PRIVATE_PRACTICE_TOOLS_ROOT_URL}/hipaa-compliance-kit-10th` as const;

export const MY_COURSES_URL = "/my-courses";
export const COURSE_CATALOG_URL = `${COURSE_CATALOG_ROOT_URL}/:qualifier` as const;

export const MY_WEBINARS_URL = "/my-webinars";

export const CERTIFICATE_PROGRAMS_SLUG = `certificate-programs`;
export const CERTIFICATE_PROGRAMS_URL =
  `${COURSE_CATALOG_ROOT_URL}/${CERTIFICATE_PROGRAMS_SLUG}` as const;

export const PRE_LICENSING_COURSES_SLUG = "pre-licensing-courses";
export const PRE_LICENSING_COURSES_URL =
  `${COURSE_CATALOG_ROOT_URL}/${PRE_LICENSING_COURSES_SLUG}` as const;
export const SAVING_PACKAGES_URL = `${COURSE_CATALOG_ROOT_URL}/savings-packages` as const;
export const SUPERVISION_PACKAGES_URL = `${COURSE_CATALOG_ROOT_URL}/supervision-packages` as const;
export const PACKAGES_URL = "/packages/:slug" as const;

export const YOUTUBE_URL = "https://www.youtube.com/user/zurinstitute";
export const TWITTER_URL = "https://www.twitter.com/ZurInstitute";
export const FACEBOOK_URL = "https://www.facebook.com/ZurInstitute";
export const LINKEDIN_URL = "https://www.linkedin.com/company/zur-institute";
